@use "theme/src/colors";

.selectdropdown {
  position: relative;

  .selectdropdown__icon {
    vertical-align: middle;
  }
}

.selectdropdown--static {
  button {
    cursor: default;
    color: colors.$gray;
  }
}

.selectdropdown__dropdown {
  position: absolute;
  top: 0;
  box-shadow: 0px 32px 48px rgba(0, 0, 0, 0.25);
  transition: transform 200ms var(--emo-out);
  transform: scale(0);
  transform-origin: top;
  z-index: 1;
  padding: 14px 0;
  border-radius: 8px;
  background-color: hsl(var(--popover));
  width: 100%;
}

.selectdropdown__dropdown--visible {
  transform: scale(1);
}

.selectdropdown__dropdown_item {
  text-align: left;
  width: 100%;

  > :hover {
    opacity: 0.7;
  }
}

.selectdropdown__dropdown_item_option {
  margin: 0 32px;
}

.selectdropdown__dropdown_item--selected {
  cursor: default;

  > :hover {
    opacity: inherit;
  }
}

.selectdropdown__dropdown_item_button {
  text-align: left;
  width: 100%;
}
