:root {
  --emo: cubic-bezier(0.4, 0, 0.2, 1);
  --emo-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --emo-out: cubic-bezier(0, 0.2, 0.2, 1);
  --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);

}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeUpIn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes flyUp {
  0% {
    bottom: -10%;
  }
  100% {
    bottom: clamp(90px, 10%, 100%);
  }
}

@keyframes flyUp0 {
  0% {
    bottom: -50%;
  }
  100% {
    bottom: 0%;
  }
}

.fadeIn {
  animation: 500ms fadeIn forwards;
}
.fadeOut {
  animation: 500ms fadeOut forwards;
}

@keyframes expandFade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 1;
  }
  66% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
  }
}

.expandFade {
  animation: 1500ms expandFade ease-out infinite;
}
