@use "theme/src/util";

$height: 240px;
$bottom-spacing: 20px;
$starting-point: $height + $bottom-spacing;

.container {
  right: 20px;
  bottom: $bottom-spacing;
  aspect-ratio: 16/9;
  height: $height;
  width: auto;
  animation: translateUp 500ms ease-in-out;
}

@keyframes translateUp {
  0% {
    transform: translateY(24px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

$loading-screen-animation-duration: 1000ms;

.loading {
  transition: opacity $loading-screen-animation-duration;
  &.exiting {
    opacity: 0;
  }
}

:export {
  loadingAnimationDuration: util.strip-unit($loading-screen-animation-duration);
}
