@use "theme/src/buttons";
@use "theme/src/text";

.btn {
  transition: 300ms all;
  opacity: 0.75;
  cursor: pointer;
}

.btn:hover {
  opacity: 1;
}

.btn-disabled {
  opacity: 0.2;
  pointer-events: none;
}

// Round button

.btn-round {
  @extend %btn-scale-behavior;

  border-radius: 500px;
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.3);
  transition: 400ms all var(--emo-in-out);
  margin: 0 auto;
}

.btn-round--outline {
  border: buttons.$btn-border;
  box-shadow: none;

  &:hover {
    border-color: #000;
  }
}

.btn-round button {
  padding: 12px 24px;
}

.btn-round i,
.btn-round svg {
  padding-right: 4px;
  vertical-align: bottom;
}

.btn-round input {
  font-weight: text.$real-text-regular;
  padding: 12px 24px;
}
