@use "theme/src/colors";

.orgselect__button {
  margin-top: 30px;
}

.orgselect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.orgselect__input-inputtext {
  margin-top: 64px;
  margin-bottom: 64px;
  display: relative;
}

.orgselect__input-error {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  color: colors.$red;
}

.orgselect__back-button {
  top: 0;
  left: 0;
}
