.container {
  --progress: 0;
  //ease-out-back
  animation: openAnim 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  @keyframes openAnim {
    0% {
      opacity: 0;
      transform: scale(0.1, 4);
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
}

.progressDelayed {
  // Animate scale instead of width.
  // Animating width would cause lag because the browser would have to recalculate layout on every frame.
  // See https://stackoverflow.com/a/28799748
  transform: scaleX(var(--progress));
  //ease-in-cubic
  transition: transform 1000ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 250ms;
}
.progressInstant {
  transform: scaleX(var(--progress));
  transition: transform 150ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

.shimmer {
  animation: shimmer 4s infinite linear;

  @keyframes shimmer {
    // Set the animation to ~1.25s, and the rest of the animation is "delay" between intervals.
    0% {
      transform: translateX(-150%);
    }
    35%,
    100% {
      transform: translateX(150%);
    }
  }
}

.startLoadPulse {
  animation: pulse 0.7s ease-out 0.15s;
  filter: blur(8px);

  @keyframes pulse {
    0% {
      opacity: 0.25;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.5, 4);
    }
  }
}
