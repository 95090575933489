::view-transition-group(space-container) {
  animation-duration: 280ms;
  z-index: 901; // 1 more than the navbar
}

// Prevents ghosting when moving between miniplayer and featured
::view-transition-old(homepage-spaces-container),
::view-transition-new(homepage-spaces-container) {
  animation: none;
}

// Don't show the old miniplayer at all, prevents ghosting artifact
::view-transition-old(space-container) {
  display: none;
}

// disable the default animation of fading in the new node
::view-transition-new(space-container) {
  animation: none;
}
