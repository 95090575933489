/* 
Global styles for any web application in the Spatial monorepo. The root Tailwind config can be 
found at `libs/theme/src/tailwind.config.ts`.

- Includes Tailwind directives
- Adds a minimal amount of global styles and classes, such as resets and typography

Tailwind documentation: https://tailwindcss.com/docs/installation
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: theme(fontFamily.body);
    font-size: 100%;
  }

  /* For some reason, font-variant-numeric is overwritten by default by browser styles
  on `button` elements, so target `button` elements specifically.
  Required to align numbers correctly.
  Could also use font-feature-settings: "lnum" on, "pnum" on; and we would not have
  to specifically target buttons, but that is not recommended according to MDN:
  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Fonts/OpenType_fonts_guide
  and caniuse: https://caniuse.com/?search=font-feature-settings */
  html,
  button,
  input,
  textarea {
    font-variant-numeric: lining-nums proportional-nums;
  }
}

/* 
CSS variables for colors.
- Figma: https://www.figma.com/file/x105MEPt0PftFzb3rRVa6V/Spatial-Platform-2023-2024?type=design&node-id=1344-11742&mode=design&t=3WSRbXv3QxYxFEnB-4
- Documentation: https://web-docs.spatial.io/colors

When adding a new color:
- The CSS varialbe shoudl be defined without the color space funciton. See https://tailwindcss.com/docs/customizing-colors#using-css-variables
- You will need to add the corresponding color to the Tailwind config in `libs/theme/src/tailwind.config.ts`. 
- You may need to update other files. See the comments in this file for more information.

The naming convention for many of the semantic colors draws inspiration from  https://ui.shadcn.com/docs/theming.
*/
@layer base {
  /* 
  ------ Base Colors ------
  These colors correspond to an actual shade/hex value. Generally, you should avoid using these colors directly,
  and use the semantic colors instead. For example, use `var(--background)` instead of `var(--white)`.
  */
  :root {
    /* Core Colors */
    --black: 0 0% 0%;
    --white: 0 0% 100%;
    --almost-white: 0 0% 96%; /* #F5F5F5/rgb(245, 245, 245) (gray-100) */
    --almost-black: 0 0% 15%; /* #262626/rgb(38, 38, 38) (gray-800) */

    --blue: 212 100% 50%; /* #0077ff/rgb(0, 119, 255) */
    --red: 360 100% 60%; /* #ff3333/rgb(255, 51, 51) */
    --green: 148 100% 50%; /* #00ff77/rgb(0, 255, 119) */
    --yellow: 60 100% 50%; /* #ff0/rgb(255, 255, 0) */

    /* Grayscale: we use Tailwind's neutral palette */
    --gray-50: 0 0% 98%; /* #FAFAFA/rgb(250, 250, 250) */
    --gray-100: 0 0% 96%; /* #F5F5F5/rgb(245, 245, 245) */
    --gray-200: 0 0% 90%; /* #E5E5E5/rgb(229, 229, 229) */
    --gray-300: 0 0% 83%; /* #D4D4D4/rgb(212, 212, 212) */
    --gray-400: 0 0% 64%; /* #A3A3A3/rgb(163, 163, 163) */
    --gray-500: 0 0% 45%; /* #737373/rgb(115, 115, 115) */
    --gray-600: 0 0% 32%; /* #525252/rgb(82, 82, 82) */
    --gray-700: 0 0% 25%; /* #404040/rgb(64, 64, 64) */
    --gray-800: 0 0% 15%; /* #262626/rgb(38, 38, 38) */
    --gray-900: 0 0% 9%; /* #171717/rgb(23, 23, 23) */
    --gray-950: 0 0% 4%; /* #0A0A0A/rgb(10, 10, 10) */

    /* 
    Light and dark definitions of theme-aware colors 
    This allows us to use a specific color in code, for example if you want to use `var(--muted-light)` always for 
    a specific element.
    */
    /* ------ Light Theme ------ */
    --background-light: var(--white);
    --foreground-light: var(--black);
    --border-light: var(--gray-200);
    --accent-light: var(--gray-100); /* Lighter than border */
    --accent-foreground-light: var(--gray-900);
    --muted-light: var(--gray-100);
    --muted-foreground-light: var(--gray-500);
    --popover-light: var(--white);
    --modal-light: var(--white);

    --toast-warning-light: 60 100% 90%; /* #FFFFCC/rgb(255, 255, 204) */
    --toast-warning-foreground-light: 60 79% 32%; /* #929211/rgb(146, 146, 17) */
    --toast-success-light: 148 100% 90%; /* #CCFFE4/rgb(204, 255, 228) */
    --toast-success-foreground-light: 148 77% 39%; /* #17AE5D/rgb(23, 174, 93) */
    --toast-error-light: 360 100% 92%; /* #FFD6D6/rgb(255, 214, 214) */
    --toast-error-foreground-light: 360deg 79% 61% /* #EA4f4f/rgb(234, 79, 79) */;

    /* ------ Dark Theme ------ */
    --background-dark: var(--gray-900);
    --foreground-dark: var(--white);
    --border-dark: var(--gray-700);
    --accent-dark: var(--gray-700); /* Same as border */
    --accent-foreground-dark: var(--gray-50);
    --muted-dark: var(--gray-800);
    --muted-foreground-dark: var(--gray-400);
    --popover-dark: var(--gray-800);
    --modal-dark: var(--gray-900);

    /* Toast-specific colors: https://www.figma.com/file/UmdIbrlUQdmYEkZyXMJERS/Toasts?type=design&node-id=0-1&mode=design&t=PQGufNQVAZa0Y9F2-0 */
    --toast-warning-dark: 60 68% 21%; /* #787800/rgb(120, 120, 0) */
    --toast-warning-foreground-dark: var(--yellow);
    --toast-success-dark: 140 60% 17%; /* #005628/rgb(0, 86, 40) */
    --toast-success-foreground-dark: var(--green);
    --toast-error-dark: 0 49% 22%; /* #721515/rgb(114, 21, 21) */
    --toast-error-foreground-dark: var(--red);
  }

  /* 
  ------ Semantic (Theme-aware Colors) ------
  These colors are named after how they should be used semantically. For example, if you want muted text: use `text-muted-foreground`
  rather than `text-gray-xxx`.

  90% of the time, you should use these colors instead of the base colors! Exceptions are generally when using the highlight colors.

  These colors are theme-aware. They will be a different color based on the theme (light/dark).
  */
  :root {
    --background: var(--background-light);
    --foreground: var(--foreground-light);

    --border: var(--border-light);
    --accent: var(--accent-light);
    --accent-foreground: var(--accent-foreground-light);
    --muted: var(--muted-light);
    --muted-foreground: var(--muted-foreground-light);
    --popover: var(--popover-light);
    --modal: var(--modal-light);

    --toast-warning: var(--toast-warning-light);
    --toast-warning-foreground: var(--toast-warning-foreground-light);
    --toast-success: var(--toast-success-light);
    --toast-success-foreground: var(--toast-success-foreground-light);
    --toast-error: var(--toast-error-light);
    --toast-error-foreground: var(--toast-error-foreground-light);
  }

  .dark {
    --background: var(--background-dark);
    --foreground: var(--foreground-dark);

    --border: var(--border-dark);
    --accent: var(--accent-dark);
    --accent-foreground: var(--accent-foreground-dark);
    --muted: var(--muted-dark);
    --muted-foreground: var(--muted-foreground-dark);
    --popover: var(--popover-dark);
    --modal: var(--modal-dark);

    --toast-warning: var(--toast-warning-dark);
    --toast-warning-foreground: var(--toast-warning-foreground-dark);
    --toast-success: var(--toast-success-dark);
    --toast-success-foreground: var(--toast-success-foreground-dark);
    --toast-error: var(--toast-error-dark);
    --toast-error-foreground: var(--toast-error-foreground-dark);
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
  }
}

/* 
Adjusts h-screen and min-h-screen for mobile browsers where 100vh is not reliable. 
See: 
- https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
- https://benborgers.com/posts/tailwind-h-screen
*/
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
  .max-h-screen {
    max-height: -webkit-fill-available;
  }
}

/* 
Custom animation classes
*/
@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}
