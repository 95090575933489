@use "theme/src/colors";

// Adapted from https://github.com/rstacruz/nprogress/blob/master/nprogress.css
// Removes unnecessary styles (spinner), and changes the color

$color: colors.$blue;
$zIndex: 3000;

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $color;

  position: fixed;
  z-index: $zIndex;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $color, 0 0 5px $color;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
