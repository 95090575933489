@use "theme/src/colors";
@use "theme/src/screens";
@use "theme/src/text";

.inputtext__input {
  background-color: transparent;
  border: none;
  text-align: center;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-skip: none;
  font-family: text.$real-text;
  font-weight: text.$real-text-regular;
  -webkit-text-fill-color: colors.$blue-light;
  color: colors.$blue-light;
  caret-color: colors.$light-gray;
  outline: none;
  width: 100%;

  &::placeholder {
    -webkit-text-fill-color: colors.$light-gray;
    color: colors.$light-gray;
  }
}

.inputtext,
.inputtext__input,
.inputtext--large,
.inputtext--large .inputtext__input {
  font-size: 5rem;

  @media only screen and (max-width: screens.$mobile) {
    font-size: 3rem;
  }
}

.inputtext--small,
.inputtext--small .inputtext__input {
  font-size: 2rem;

  @media only screen and (max-width: screens.$mobile) {
    font-size: 1rem;
  }
}

// type="checkbox"

.inputcheckbox {
  display: flex;
  cursor: pointer;
  user-select: none;
}

.inputcheckbox__hidden_input {
  // Hide browser checkbox
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

.inputcheckbox__checkmark--black {
  --dim-color: #{rgba(colors.$black, 0.4)};
  --primary-color: #{colors.$black};
  --secondary-color: #{colors.$white};
}

.inputcheckbox__checkmark--white {
  --dim-color: #{rgba(colors.$white, 0.4)};
  --primary-color: #{colors.$white};
  --secondary-color: #{colors.$black};
}

.inputcheckbox__checkmark {
  flex-shrink: 0;
  vertical-align: middle;
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid var(--dim-color);
  border-radius: 2px;
  margin: 0 10px 1px 0;
  opacity: 1;
}

.inputcheckbox:hover .inputcheckbox__checkmark,
.inputcheckbox__hidden_input:checked ~ .inputcheckbox__checkmark {
  border: 1px solid var(--primary-color);
  opacity: 1;
}

.inputcheckbox__checkmark:after {
  // Create the checkmark/indicator (hidden when not checked)
  content: "";
  position: absolute;
  display: none;
  width: 4px;
  height: 9px;
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-left: 4px;
  margin-top: 0px;
}

.inputcheckbox__hidden_input:active ~ .inputcheckbox__checkmark:after {
  // Show the checkmark when active
  display: block;
  border-color: var(--primary-color);
}

.inputcheckbox__hidden_input:checked ~ .inputcheckbox__checkmark:after {
  // Show the checkmark when checked
  border-color: var(--secondary-color);
  display: block;
}

.inputcheckbox__hidden_input:checked ~ .inputcheckbox__checkmark {
  background-color: var(--primary-color);
}

.inputcheckbox__text {
  font-size: 1rem;
}

// Textarea inputs
.inputtextarea {
  outline: none;
  font-family: text.$real-text;
  caret-color: colors.$blue-light;

  &:focus {
    border: 2px solid colors.$blue-light;
  }
}
