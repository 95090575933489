@use "theme/src/text";

/* TYPOGRAPHY */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class="3d"] {
  transform-style: preserve-3d;
  transform: translateZ(120px);
}

/* Lists */
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
