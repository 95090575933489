@use "theme/src/text";
@use "theme/src/colors";
@use "theme/src/screens";
@use "theme/src/util";
@use "web/core/src/js/components/navbar/variables.module.scss" as navbarVariables;
@import "web/core/src/css/globals/type";
@import "web/core/src/css/components";
@import "web/core/src/css/animations";

// These are here because Next.js doesn't allow for arbitrary global CSS imports.
// See https://nextjs.org/docs/messages/css-global.
// TODO(http://go/i/DEV-7830) - Migrate these to Sass modules
@import "web/core/src/css/components/buttons";
@import "web/core/src/js/components/org-select/org-select";
@import "web/core/src/js/components/select-dropdown/select-dropdown";
@import "web/space/src/components/room";
@import "web/space/src/space-container";

:root {
  --navbar-height: #{navbarVariables.$mobile-height};
  @media screen and (min-width: screens.$sm) {
    --navbar-height: #{navbarVariables.$height};
  }
  --top-banner-height: calc(#{navbarVariables.$mobile-height} / 2);
  @media screen and (min-width: screens.$sm) {
    --top-banner-height: calc(#{navbarVariables.$height} / 2);
  }
}

input {
  padding: 0px;
  margin: 0px;
}

input {
  font-family: text.$real-text;
  font-weight: text.$real-text-regular;
}

select {
  font: inherit;
  margin: 0;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
}

label.select {
  position: relative;
}

label.select:after {
  content: "arrow_drop_down";
  font: 32px "Material Icons";
  opacity: 0.6;
  right: 8px;
  top: -8px;
  position: absolute;
  pointer-events: none;
}

.hover {
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.5s var(--emo-out);
}

.hover:hover {
  opacity: 1;
}

a {
  color: hsl(var(--foreground));
}

svg {
  user-select: none;
}

@mixin icon-font-size($size) {
  height: 1em;
  width: 1em;
  font-size: $size;
}

i.medium,
svg.medium {
  font-size: 32px;
  font-weight: text.$real-text-regular;
}

i.large,
svg.large {
  font-size: 48px;
  font-weight: text.$real-text-regular;
}
