@use "./variables.module.scss" as Room;
@use "theme/src/colors";
@use "theme/src/text";

.room {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.room * {
  pointer-events: all;
}

.room .btn-circle--disabled,
.room .btn-circle--outline {
  border: none;
  box-shadow: none;
  color: colors.$room-button-default-foreground;
}

.room .btn-circle--outline {
  background-color: colors.$room-button-default-background;

  &:hover {
    background-color: colors.$room-button-default-background;
    box-shadow: 0px 16px 48px 0px colors.$black--shadow;
  }
}

.scaleIn {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 400ms;
  animation-name: scaleIn;
}

.scaleOut {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 400ms;
  animation-name: scaleOut;
}
